import { template as template_bd043b3fc4984854ad366110a8bc9f56 } from "@ember/template-compiler";
const FKLabel = template_bd043b3fc4984854ad366110a8bc9f56(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
